function fftData(origin, data) {
  let fftList = [
      'trainDirection', 
      'advancedProject', 
      'crcSoure', 
    ];
  for( let key in origin) {
    if (fftList.includes(key)) {
      data[key] = origin[key].split(',');
    } else if (key === 'address') {
      data.area = origin[key].split('/')[0].split(' ');
      data.detailArea = origin[key].split('/')[1]
    } else {
      data[key] = origin[key];
    }
  }
  return data
}

export default fftData